/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
  font-family: 'King';
  src: url(/assets/fonts/King/King-Sans.ttf) format('truetype');
}

@font-face {
  font-family: 'Lavigne';
  src: url(/assets/fonts/Lavigne/Lavigne.ttf) format('truetype');
}
@font-face {
  font-family: 'KongQuest';
  src: url(/assets/fonts/Kong-Quest/KongQuest-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: url(/assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Nova';
  src: url(/assets/fonts/Nova/NOVABOLD.otf) format('truetype');
}
body {
  font-family: Lato, serif;
}

.mainText {
  color: white;
  font-size: 21pt;
  font-weight: bolder;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.secondaryText {
  color: white;
  font-size: 18pt;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.titleText {
  padding-top: 20px;
  text-align: center;
  color: white;
  font-size: 16pt;
  font-weight: bolder;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.krobapadding-top {
  padding-top: 15px;
}
.krobapadding-left {
  padding-left: 15px;
}
.krobapadding-right {
  padding-right: 15px;
}
.krobapadding-bottom {
  padding-bottom: 15px;
}
.krobapadding {
  padding: 30px;
}

.backgroundOverlay {
  background: #007aff;
  height: 100vh;
  width: 100vw;
}

.background-image {
  //background: url('/assets/naturebg.jpg') no-repeat fixed center;
  //background-size:cover;
  //position:fixed;
  object-fit: cover;

  width: 100%;
  height: 100vh;

  filter:brightness(50%);
  z-index:-1;
}

ion-content {
  --background: transtarent;
}

.customBackHeader{
  position: fixed;
  z-index: 1;
}

.actionClass{
  cursor: pointer;
}
